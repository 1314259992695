import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Company, Companies } from 'src/app/models/company';
import { ISoftware, ProductFamilies, ProductFamilyNames, SoftwareTypes, SoftwareTypeNames } from 'src/app/models/ISoftware';
import { Permissions, UserProfile } from 'src/app/models/user';

import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { SoftwaresService } from 'src/app/services/softwares.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-softwares-list-page',
    templateUrl: './softwares-list-page.component.html',
    styleUrls: ['./softwares-list-page.component.scss']
})
export class SoftwaresListPageComponent implements OnInit {

    _ = _;

    softwares: Array<ISoftware>;

    duplicateProductNumbers: Array<string>;

    searchName: string;
    searchType: string;
    searchProductFamily: string;
    searchProductNumber: string;
    searchParams: Array<any>;

    allItemsChecked: boolean;
    anyItemSelected: boolean;
    selectedItems: object;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Available and supported software types
     */
    softwareTypes: Array<string> = [];

    softwareTypeNames = SoftwareTypeNames;

    /**
     * Available and supported product families
     * systemCode
     */
    productFamilies: Array<string> = [];

    productFamilyNames = ProductFamilyNames;

    /**
     * User profile
     */
    user: UserProfile;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    fileIdMapping: any;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        public stateService: StateService,
        private softwaresService: SoftwaresService
    ) {
        this.companies = Companies;
        this.softwareTypes = SoftwareTypes;
        this.productFamilies = ProductFamilies;
        this.softwares = [];
        this.duplicateProductNumbers = [];

        this.allItemsChecked = false;
        this.anyItemSelected = false;
        this.selectedItems = {};
        this.resetSearchParams();

        this.fileIdMapping = {};
    }

    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.file = '';

            this.user = this.authService.userProfile;
            this.permissions = this.user.permissions;
            this.search();

            // this.getFileIdMapping();
        });
    }

    search() {
        this.checkSearchParams();
        this.softwaresService.getSoftwares(this.searchParams).subscribe((softwares: Array<ISoftware>) => {
            this.softwares = softwares;
            this.checkForDuplicates();
            this.filter();
            this.resetSelectedItems();
        });
    }

    getFirmwaresByProductFamily() {
        this.softwaresService.getFirmwaresByProductFamily('X5MIGWELDER').subscribe((softwares: Array<ISoftware>) => {
            this.softwares = softwares;
            this.filter();
            this.resetSelectedItems();
        });
    }

    checkSearchParams() {
        this.searchParams = [];
        if (this.searchName !== '') {
            this.searchParams.push({ key: 'name', value: this.searchName });
        }
        if (this.searchType !== '') {
            this.searchParams.push({ key: 'softwareType', value: this.searchType });
        }
        if (this.searchProductFamily !== '') {
            this.searchParams.push({ key: 'productFamily', value: this.searchProductFamily });
        }
        if (this.searchProductNumber !== '') {
            this.searchParams.push({ key: 'productNumber', value: this.searchProductNumber });
        }
    }

    checkForDuplicates() {
        this.duplicateProductNumbers = [];
        const checkList = [];
        for (const s of this.softwares) {
            if (s.data.productNumber) {
                if (checkList.indexOf(s.data.productNumber) >= 0) {
                    this.duplicateProductNumbers.push(s.data.productNumber);
                }
                checkList.push(s.data.productNumber);
            }
        }
    }

    filter() {

    }

    resetSearchParams() {
        this.searchName = '';
        this.searchType = '';
        this.searchProductFamily = '';
        this.searchProductNumber = '';
        this.searchParams = [];
    }

    /**
     * Reset selections (on search)
     */
    resetSelectedItems() {
        this.selectedItems = {};
        for (const item of this.softwares) {
            this.selectedItems[item.id] = false;
        }
        this.allItemsChecked = false;
        this.checkSelectedItems();
    }

    /**
     * Check is there any item selected
     */
    checkSelectedItems() {
        this.anyItemSelected = _.some(_.values(this.selectedItems), (value) => value === true);
    }

    /**
     * Select / Deselect all items
     */
    selectAllItems(allChecked) {
        _.map(this.softwares, (item) => {
            this.selectedItems[item.id] = allChecked;
        });
        this.checkSelectedItems();
    }

    /**
     * Returns selected items
     */
    getSelectedItemsForBulk() {
        const items = _.map(
            _.filter(this.softwares, (license) => {
                return license && this.selectedItems[license.id] ? true : false;
            }),
            (item) => {
                return {
                    id: item.id
                };
            }
        );
        return items;
    }

    randomStr(len, arr) {
        let ans = '';
        for (let i = len; i > 0; i--) {
            ans += arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    createNewSoftware() {
        this.router.navigate(['maintain-software', 'new']);
    }

    openSoftware(id) {
        this.router.navigate(['maintain-software', id]);
    }

    deleteSelectedItems() {
        // const items = this.getSelectedItemsForBulk();
        // this.licensesService.deleteLicenses(items).subscribe((response) => {
        //     setTimeout(() => {
        //         this.search();
        //     }, 2000);
        // });
    }

    getFileIdMapping() {
        this.softwaresService.getFileIdMapping().subscribe((mapping) => {
            this.fileIdMapping = mapping;
        }, (error) => {

        });
    }

}
