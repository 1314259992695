import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Company, Companies } from 'src/app/models/company';
import { Compatibility, getCompatibleProducts, ICompatibility, ISoftware, ISoftwareRevision, ProductFamilies, ProductFamilyNames, ProductNames, SoftwareTypes } from 'src/app/models/ISoftware';
import { IUploadUrlResponse, IDownloadUrlResponse, IParseKbfRequest } from 'src/app/models/ISWSoftwareApi';

import { AuthService } from 'src/app/services/auth.service';
import { LocaleService } from 'src/app/services/locale.service';
import { MachineryService } from 'src/app/services/machinery.service';
import { StateService } from 'src/app/services/state.service';
import { SoftwaresService } from 'src/app/services/softwares.service';
import { ZipReaderService } from 'src/app/services/zip-reader.service';

import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';

import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { DiscardConfirmDialogComponent } from 'src/app/shared/dialogs/components/discard-dialog/discard-confirm-dialog.component';

const SUPPORTED_ATTACHMENTS = [
    { extension: 'kbf', type: '' },
    { extension: 'kbf', type: 'application/octet-stream' },
    { extension: 'zip', type: 'application/zip' },
    { extension: 'zip', type: 'application/x-zip-compressed' },
    { extension: 'gz', type: 'application/x-gzip' },
];

@Component({
    selector: 'app-software-page',
    templateUrl: './software-page.component.html',
    styleUrls: ['./software-page.component.scss']
})
export class SoftwarePageComponent implements OnInit {

    _ = _;

    id: string;
    software: ISoftware;
    attachment: any;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Available and supported software types
     */
    softwareTypes: Array<string> = [];

    /**
     * Available and supported product families
     * systemCode
     */
    productFamilies: Array<string> = [];

    productFamilyNames = ProductFamilyNames;

    productNames = ProductNames;

    compatibleProducts: Array<string> = [];


    copyFromRevisionIndex: number;

    /**
     * Compatibility of product families sub system
     */
    compatibility: ICompatibility = Compatibility;

    /**
     * List of softwares that can be added to PROGRAM_PACK / FEATURE_PACK
     */
    softwaresForPack: Array<ISoftware> = [];

    /**
     * Authenticated user role
     * koy_user / sub_user / cus_dealer / etc.
     */
    authUserRole = '';

    showRevision = [];
    showSelectIncludedSoftwares = false;

    restrictedPartnerId: string;
    restrictedPartnerIdRev: Array<string>;

    state: {
        loading: boolean;
        loadingBuffer: number;
        loadingStatus: string;
        originalData: any;
    };

    showKoyTools: boolean;

    showCompatibleProducts: boolean;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public localeService: LocaleService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private machieryService: MachineryService,
        private softwaresService: SoftwaresService,
        private stateService: StateService,
        private zipReaderService: ZipReaderService
    ) {
        this.showKoyTools = false;
        this.companies = Companies;
        this.softwareTypes = SoftwareTypes;
        this.productFamilies = ProductFamilies;
        this.restrictedPartnerId = '';
        this.restrictedPartnerIdRev = [];
        this.showCompatibleProducts = false;
    }

    ngOnInit() {
        this.state = {
            loading: false,
            loadingBuffer: 0,
            loadingStatus: null,
            originalData: null
        };
        setTimeout(() => {
            this.authUserRole = this.authService.userProfile.role;
            this.id = _.get(this.activatedRoute.snapshot.params, 'id');
            if (this.id === 'new') {
                this.stateService.state.file = this.id;
                this.software = this.getNewSoftwareTemplate();
                this.software.data.revisions.push(this.getNewSoftwareRevisionTemplate());
                this.initShowRevisions();
            } else {
                this.getSoftware(this.id);
            }
        });
    }

    /**
     * Detect changes, if current page data contains unsaved changes open discard confirmation dialog
     */
    canDeactivate(): Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            // If current page data is remained unchanged return true and allow routing to another url
            if (this.software && this.state.originalData
                && _.isEqual(this.software.id, this.state.originalData.id)
                && _.isEqual(this.software.data, this.state.originalData.data)
            ) {
                resolve(true);
                return;
            }
            // Otherwise ask the user confirmation with the dialog. Return its promise which resolves to true or false when the user decides
            const dialogRef = this.dialog.open(DiscardConfirmDialogComponent);
            dialogRef.afterClosed().subscribe(results => {
                resolve(results);
            });
        });
    }

    getSoftware(id) {
        this.softwaresService.getSoftware(id).subscribe((software) => {
            this.software = software as ISoftware;
            this.stateService.state.file = this.software.data.name;
            this.initShowRevisions();
            this.checkSoftwareTypeAndProductFamily();
            this.storeOriginalData();
            this.refactorSoftware();
            this.updateCompatibleProducts();
            console.log('software', software);
        });
    }

    /**
     * Store original software data.
     * Activate save button
     * Used for canDeactivate() to check any changes. (Shows discard changes dialog)
     */
    storeOriginalData() {
        console.log('storeOriginalData');
        this.state.originalData = _.cloneDeep(this.software);
    }

    /**
     * Insert refactoring code here
     * Modify software on the fly when data model have changed
     */
    refactorSoftware() {
        // if (typeof this.software.data.restrictedCompany === 'string' && this.software.data.restrictedCompany !== '') {
        //     this.software.data.restrictedCompany = [this.software.data.restrictedCompany];
        // }
        // if (typeof this.software.data.restrictedPartnerId === 'string' && this.software.data.restrictedPartnerId !== '') {
        //     this.software.data.restrictedPartnerId = [this.software.data.restrictedPartnerId];
        // }
        // for (const revision of this.software.data.revisions) {
        //     if (typeof revision.restrictedCompany === 'string' && revision.restrictedCompany !== '') {
        //         revision.restrictedCompany = [revision.restrictedCompany];
        //     }
        //     if (typeof revision.restrictedPartnerId === 'string' && revision.restrictedPartnerId !== '') {
        //         revision.restrictedPartnerId = [revision.restrictedPartnerId];
        //     }
        // }
        if (this.software.data.softwareType === 'PROGRAM_PACK' || this.software.data.softwareType === 'FEATURE_PACK') {
            for (const rev of this.software.data.revisions) {
                rev.file.filename = this.software.data.name.replace(/ /g, '_') + '.zip';
                rev.file.contentType = 'application/zip';
                /**
                 * Trigger autofixing below
                 */
                if (rev.includedSoftwares === null) {
                    rev.includedSoftwares = []
                }
            }
        }
        // Fix includedSoftwares, make sure they are Array instead of object
        if (this.software.data.revisions) {
            for (const swRev of this.software.data.revisions) {
                const includedSoftwares = [];
                if (swRev.includedSoftwares && typeof (swRev.includedSoftwares) === 'object') {
                    for (const key in swRev.includedSoftwares) {
                        if (Object.prototype.hasOwnProperty.call(swRev.includedSoftwares, key)) {
                            includedSoftwares.push(swRev.includedSoftwares[key]);
                        }
                    }
                    swRev.includedSoftwares = includedSoftwares;
                    console.log('rev includedSoftwares autofixed');
                }
            }
        }
    }

    cancel() {
        this.router.navigate(['maintain-software']);
    }

    checkRestrictions(rev: ISoftwareRevision) {

        if (!rev.private) {
            rev.restrictedCompany = null
            rev.restrictedPartnerId = null
        }
    }

    save() {
        this.setLoading(true, 25, 'Saving software');
        if (this.software.id === null) {
            this.softwaresService.createSoftware(this.software).subscribe((result: string) => {
                if (result) {
                    this.id = result;
                    this.software.id = result;
                }
                this.storeOriginalData();
                this.notify('Software saved');
                this.setLoading(true, 50, 'Reloading');
                setTimeout(() => {
                    this.router.navigate([`/maintain-software/${this.software.id}`]);
                    this.getSoftware(this.software.id);
                    this.checkSelectedNotUploadedAttachment();
                }, 2000);
            }, (error) => {
                console.log('error', error);
                this.notifyError('Failed to save software: ' + (error.error.errorMessage || error.message));
                this.setLoading(false);
            });
        } else {
            // check that the restricted fields are really cleared

            if (!this.software?.data?.private && this.software.data) {
                this.software.data.restrictedCompany = null
                this.software.data.restrictedPartnerId = null
            }

            this.software.data.revisions.forEach(rev => this.checkRestrictions(rev))
            this.softwaresService.updateSoftware(this.software).subscribe(() => {
                this.notify('Software saved');
                this.setLoading(true, 50, 'Reloading');
                setTimeout(() => {
                    this.getSoftware(this.software.id);
                    this.checkSelectedNotUploadedAttachment();
                }, 2000);
            }, (error) => {
                console.log('error', error);
                this.notifyError('Failed to save software: ' + (error.error.errorMessage || error.message));
                this.setLoading(false);
            });
        }
    }

    setLoading(loading: boolean = false, buffer: number = 0, status: string = '') {
        this.state.loading = loading;
        this.state.loadingStatus = status;
        this.state.loadingBuffer = buffer;
    }

    openDeleteConfirmDialog() {
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.delete_confirmation',
                content: 'confirm_dialog.are_you_sure_you_want_to_delete'
            }
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                this.delete();
            }
        });
    }

    delete() {
        if (this.software.id !== null) {
            this.setLoading(true, 33, 'Deleting');
            this.softwaresService.deleteSoftware(this.software.id).subscribe((result: string) => {
                this.notify('Software deleted');
                this.setLoading(true, 66, 'Redirecting');
                setTimeout(() => {
                    this.router.navigate([`/maintain-software`]);
                    this.setLoading(false);
                }, 2000);
            }, (error) => {
                console.log('error', error);
                this.notifyError('Failed to delete software: ' + (error.error.errorMessage || error.message));
                this.setLoading(false);
            });
        }
    }

    addRevision() {
        this.software.data.revisions.push(this.getNewSoftwareRevisionTemplate());
        this.showRevision[this.software.data.revisions.length - 1] = true;
    }

    removeRevision(revisionIndex) {
        this.software.data.revisions.splice(revisionIndex, 1);
    }
    inactivateRevision(revisionIndex) {
        this.software.data.revisions[revisionIndex].inactive = true;
    }
    activateRevision(revisionIndex) {
        delete this.software.data.revisions[revisionIndex].inactive;
    }


    /**
     * Chip key event
     */
    restrictedChipKeyEvent(event, propertyPath, valuePath) {
        console.log('restrictedChipKeyEvent', event, propertyPath, valuePath);
        const value = _.get(this, valuePath);
        // backspace
        if (event.keyCode === 8 && value === '') {
            const property = _.get(this, propertyPath) || [];
            this.removeRestrictedParterId(propertyPath, property.length - 1);
        }
        // enter
        if (event.keyCode === 13 && value && value !== '') {
            this.addRestrictedParterId(propertyPath, valuePath);
        }
    }

    /**
     * Add restricted partner id
     */
    addRestrictedParterId(propertyPath, valuePath) {
        const value = _.get(this, valuePath);
        const property = _.get(this, propertyPath) || [];
        if (value && value !== '') {
            property.push(value.toUpperCase());
            _.set(this, propertyPath, property);
            _.set(this, valuePath, '');
        }
    }

    /**
     * Add restricted partner id
     */
    removeRestrictedParterId(propertyPath, index) {
        const property = _.get(this, propertyPath) || [];
        if (property.length > 0 && index >= 0) {
            property.splice(index, 1);
            _.set(this, propertyPath, property);
        }
    }

    downloadRevision(revisionIndex) {
        const revision = this.software.data.revisions[revisionIndex];
        const filename = revision.file.filename;
        // console.log('filename', filename);
        this.softwaresService.downloadSoftware(this.software.id, revision.id).subscribe((data: any) => {
            const type = 'application/zip';
            const content: Blob = this.b64toBlob(data, type);
            FileSaver.saveAs(content, filename);

            // console.log('data', data);
            // const fileContent = `data:${type};base64,${data}`;
            // const byteArray = Uint8Array.from(
            //     atob(data)
            //         .split('')
            //         .map(char => char.charCodeAt(0))
            // );
            // console.log(byteArray);
            // const blob = new Blob([data], { });
            // FileSaver.saveAs(new Blob([fileContent], { type: 'application/zip' }), filename);

            // console.log(blob);
            // const url = window.URL.createObjectURL(blob);
            // window.open(url);

            // const linkSource = `data:${type};base64,${data}`;
            // const downloadLink = document.createElement('a');
            // document.body.appendChild(downloadLink);

            // downloadLink.href = linkSource;
            // downloadLink.target = '_self';
            // downloadLink.download = filename;
            // downloadLink.click();
        }, (error) => {
            console.log('error', error);
            this.notifyError('Failed to download software: ' + (error.error.errorMessage || error.message));
        });

        /*
        this.softwaresService.getFileDownloadUrlFromAWSS3(this.software.id, revision.id).subscribe((result: IDownloadUrlResponse) => {
            console.log(result);
            if (result && result.temporaryDownloadUrl) {
                window.location.href = result.temporaryDownloadUrl;
                // this.softwaresService.downloadFileFromAWSS3(result.temporaryDownloadUrl).subscribe((data: any) => {
                //     console.log('data', data);
                //     const blob = new Blob([data], { });
                //     const url = window.URL.createObjectURL(blob);
                //     window.open(url);
                // });
            }
        }, (error) => {
            console.log('error', error);
            this.notifyError('Failed to download software: ' + (error.error.errorMessage || error.message));
        });
        */
    }

    copySoftwares(ownRevisionIndex: number) {
        this.software.data.revisions[ownRevisionIndex].includedSoftwares = this.software.data.revisions[this.copyFromRevisionIndex].includedSoftwares
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    downloadRevisionWithTempUrl(revisionIndex) {
        const revision = this.software.data.revisions[revisionIndex];
        this.softwaresService.getFileDownloadUrlFromAWSS3(this.software.id, revision.id).subscribe((result: IDownloadUrlResponse) => {
            console.log(result);
            if (result && result.temporaryDownloadUrl) {
                window.location.href = result.temporaryDownloadUrl;
            }
        }, (error) => {
            console.log('error', error);
            this.notifyError('Failed to download software: ' + (error.error.errorMessage || error.message));
        });
    }

    saveFile(blob, filename) {
        if ((window as any).navigator && (window as any).navigator.msSaveOrOpenBlob) {
            (window as any).navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0);
        }
    }

    getNewSoftwareTemplate() {
        const dateNow = new Date();
        const software: ISoftware = {
            id: null,
            data: {
                name: '',
                description: '',
                productNumber: '',
                productName: '',
                swCode: '',
                softwareType: null,
                productFamily: null,
                revisions: [],
                private: false
            },
            schema: {
                type: 'kemppi-sw-software',
                version: '1.0'
            },
            version: {}
        };
        return software;
    }

    getNewSoftwareRevisionTemplate() {
        const lastRevision = _.last(this.software.data.revisions);
        // Revision number will be last revision + 1 or new software first revision is 1.
        const revisionNumber: number = lastRevision ? lastRevision.revisionNumber + 1 : 1;
        const revision: ISoftwareRevision = {
            id: revisionNumber.toString(),
            revisionNumber,
            versionNumber: null,
            file: {
                filename: '',
                filepath: '',
                contentType: ''
            },
            includedSoftwares: null
        };
        return revision;
    }

    checkLatestOfficial(revision) {
        if (revision.latestOfficial) {
            for (const swRev of this.software.data.revisions) {
                if (swRev !== revision) {
                    swRev.latestOfficial = false;
                }
            }
        }
    }

    checkSoftwareTypeAndProductFamily() {
        if (this.software.data.softwareType === 'PROGRAM_PACK' && this.software.data.productFamily) {
            this.softwaresForPack = [];
            this.searchSoftwaresForPack('WELDING_PROGRAM', this.software.data.productFamily);
            this.searchSoftwaresForPack('ACTIVATED_FUNCTION', this.software.data.productFamily);
        } else if (this.software.data.softwareType === 'FEATURE_PACK' && this.software.data.productFamily) {
            this.softwaresForPack = [];
            this.searchSoftwaresForPack('ACTIVATED_FUNCTION', this.software.data.productFamily);
        }
    }

    searchSoftwaresForPack(softwareType, productFamily) {
        const searchParams = [
            { key: 'softwareType', value: softwareType },
            { key: 'productFamily', value: productFamily }
        ];
        this.softwaresService.getSoftwares(searchParams).subscribe((softwares: Array<ISoftware>) => {
            this.softwaresForPack = this.softwaresForPack.concat(softwares);
        });
    }

    includeSoftware(subSoft: ISoftware, revisionIndex, subSoftwareId, subRevisionId) {
        if (!this.software.data.revisions[revisionIndex].includedSoftwares) {
            this.software.data.revisions[revisionIndex].includedSoftwares = [];
        }
        const subRev: ISoftwareRevision = _.find(subSoft.data.revisions, { id: subRevisionId });
        // If same software already included
        const incSoftIndex = _.findIndex(this.software.data.revisions[revisionIndex].includedSoftwares, { softwareId: subSoftwareId });
        if (incSoftIndex >= 0) {
            if (this.software.data.revisions[revisionIndex].includedSoftwares[incSoftIndex].revisionId === subRevisionId) {
                // Same revision?, Remove
                this.software.data.revisions[revisionIndex].includedSoftwares.splice(incSoftIndex, 1);
            } else {
                // Change revision
                this.software.data.revisions[revisionIndex].includedSoftwares[incSoftIndex].revisionId = subRevisionId;
                this.software.data.revisions[revisionIndex].includedSoftwares[incSoftIndex].filename = subRev ? subRev.file.filename : '';
                this.software.data.revisions[revisionIndex].includedSoftwares[incSoftIndex].versionNumber = subRev ? subRev.versionNumber : '';
            }
        } else {
            // Add
            this.software.data.revisions[revisionIndex].includedSoftwares.push({
                name: subSoft.data.name,
                productNumber: subSoft.data.productNumber,
                filename: subRev ? subRev.file.filename : '',
                softwareId: subSoftwareId,
                revisionId: subRevisionId,
                versionNumber: subRev ? subRev.versionNumber : ''
            });
        }
    }

    updateIncludedSoftwares(revisionIndex) {
        if (!this.software.data.revisions[revisionIndex].includedSoftwares) {
            this.software.data.revisions[revisionIndex].includedSoftwares = [];
        }
        for (const incSoft of this.software.data.revisions[revisionIndex].includedSoftwares) {
            const soft = _.find(this.softwaresForPack, { id: incSoft.softwareId });
            const rev = _.find(soft.data.revisions, { id: incSoft.revisionId });
            if (soft && rev) {
                incSoft.name = soft.data.name;
                incSoft.productNumber = soft.data.productNumber;
                incSoft.filename = rev.file.filename;
                incSoft.versionNumber = rev.versionNumber;
            } else {
                incSoft.name = '';
                incSoft.productNumber = '';
                incSoft.filename = '';
                incSoft.versionNumber = '';
            }
        }
    }

    updateCompatibleProducts() {
        this.compatibleProducts = getCompatibleProducts(this.software.data.productFamily, this.software.data.compatibility);
    }

    // -----------------
    // Machinery
    // -----------------

    updateProductName() {
        const partNumber = this.software.data.productNumber;
        if (partNumber && partNumber !== '') {
            // Find from machinery
            this.machieryService.getPartInfo(partNumber).subscribe((partInfo: any) => {
                if (partInfo) {
                    this.software.data.productName = partInfo.partDescription;
                }
            });
        }
    }

    // -----------------
    // Attachments
    // -----------------

    /**
     * Selected file event.
     * Uses ng2FileSelect library, this is triggered after user is selected file from the computer "Browse" dialog.
     */
    async selectedFile(event, index) {
        console.log('selectedFile', event);
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            this.attachment = {
                file: null,
                content: null,
                revisionIndex: null
            };
            // console.log('selectedFile', event);
            // console.log('file', file);
            // console.log('fileExtension', fileExtension);

            if (_.find(SUPPORTED_ATTACHMENTS, { extension: fileExtension, type: file.type })) {
                this.attachment.file = file;
                const reader = new FileReader();
                reader.onload = async (ev: any) => {
                    // console.log('file read', ev);
                    this.attachment.content = ev.target.result;

                    // Automatically parse attachment
                    if (this.attachment.file && this.attachment.content) {
                        // console.log('attachment', this.attachment);
                        this.attachment.revisionIndex = index;
                        if (fileExtension === 'kbf') {
                            await this.parseKbf(this.attachment.content, index);
                        } else if (fileExtension === 'zip') {
                            await this.parseManifest(this.attachment.content, index);
                        }
                        this.software.data.revisions[index].file.contentType = file.type || 'application/octet-stream';
                        this.software.data.revisions[index].file.filename = file.name;

                        if (this.id !== 'new') {
                            // await this.saveAttachment(index);
                        }
                    }
                };
                // reader.readAsDataURL(file);
                reader.readAsArrayBuffer(file);
            } else {
                this.notifyError(`Unsupported file type ${fileExtension} [${file.type}]`);
            }
        }
    }

    async checkSelectedNotUploadedAttachment() {
        console.log('checkSelectedNotUploadedAttachment', this.attachment);
        // Readed from file? Save also the attachment
        if (this.attachment && this.attachment.file && this.attachment.content && this.attachment.revisionIndex >= 0) {
            this.setLoading(true, 75, 'Saving attachment');
            setTimeout(() => {
                this.software.data.revisions[this.attachment.revisionIndex].file.contentType = this.attachment.file.type || 'application/octet-stream';
                this.software.data.revisions[this.attachment.revisionIndex].file.filename = this.attachment.file.name;
                this.saveAttachment(this.attachment.revisionIndex);
            }, 2000);
        } else {
            this.setLoading(false);
        }
    }

    /**
     * Save attachment
     * Attachment is uploaded to userdata folder
     */
    async saveAttachment(index) {
        console.log('saveAttachment', index);
        // Software must have id (must be saved once to get id)
        if (this.attachment && this.attachment.file && this.software.id) {
            this.setLoading(true, 0, 'Saving attachment');

            const request = {
                software: this.software,
                revisionIndex: index
            };
            this.softwaresService.getFileUploadUrlToAWSS3(request).subscribe((response: IUploadUrlResponse) => {
                console.log(response);
                if (response && response.temporaryUploadUrl && response.filepath) {

                    this.softwaresService.uploadFileToAWSS3(response.temporaryUploadUrl, this.software.data.revisions[index].file.contentType, this.attachment.content).subscribe(uploadResponse => {
                        this.software.data.revisions[index].file.filepath = response.filepath;
                        this.software.data.revisions[index].file.lastModifiedDate = new Date().toISOString();

                        // Clear file selection
                        this.attachment = {
                            file: null,
                            content: null,
                            revisionIndex: null
                        };

                        // Automatically save software
                        this.save();
                    }, (error) => {
                        console.log('error', error);
                        this.notifyError('Failed to upload file: ' + (error.error.errorMessage || error.message));
                        this.setLoading(false);
                    });
                }
            }, (error) => {
                console.log('error', error);
                this.notifyError('Failed to get upload url: ' + (error.error.errorMessage || error.message));
                this.setLoading(false);
            });
        }
    }

    async parseKbf(content, index) {
        console.log('parseKbf', content);
        this.setLoading(true, 50, 'Sending and parsing kbf');
        const body: IParseKbfRequest = {
            kbfFileBase64: this.arrayBufferToBase64(content)
        };
        // console.log('base64', this.arrayBufferToBase64(content));
        return new Promise((resolve, reject) => {
            this.softwaresService.parseKbf(body).subscribe((response: ISoftware) => {
                // Response is whole software object
                console.log('response', response);
                if (this.id === 'new') {
                    this.software = response;
                    this.updateProductName();
                } else {
                    const revision = this.software.data.revisions[index];
                    const responseRevision = response.data.revisions[0];
                    // Get only readed revision parts
                    if (revision && responseRevision) {
                        revision.versionNumber = responseRevision.versionNumber;
                        revision.description = responseRevision.description;
                        revision.fileId = responseRevision.fileId;
                        revision.fileIdHex = responseRevision.fileIdHex;
                        revision.functionId = responseRevision.functionId;
                        revision.curveId = responseRevision.curveId;
                        revision.curveIdHex = responseRevision.curveIdHex;
                        revision.curveType = responseRevision.curveType;
                        revision.curveTypeHex = responseRevision.curveTypeHex;
                        // revision.file = responseRevision.file;
                    }
                }
                this.setLoading(false);
                resolve('OK');
            }, (error) => {
                this.notifyError('Failed to parse kbf: ' + (error.error.errorMessage || error.message));
                this.setLoading(false);
                reject();
            });
        });
    }

    arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    async parseManifest(content, index) {
        const revision = this.software.data.revisions[index];
        const manifest = await this.zipReaderService.getManifest(content, 'Manifest.txt');
        console.log({ manifest });
        if (manifest && revision) {
            if (_.find(this.software.data.revisions, { id: manifest.revision }) && this.software.data.revisions[index].id !== manifest.revision) {
                this.notifyError(`Manifest error. Rev. ${manifest.revision} already exists in software\n${JSON.stringify(manifest, null, 4)}`, 10000);
                // Clear file selection
                this.attachment = {
                    file: null,
                    content: null,
                    revisionIndex: null
                };
            } else {
                revision.id = manifest.revision ? manifest.revision : revision.id;
                revision.revisionNumber = manifest.revision ? parseInt(manifest.revision, 10) : revision.revisionNumber;
                revision.versionNumber = manifest.version ? manifest.version : revision.versionNumber;
                revision.name = manifest.name ? manifest.name : revision.name;
                revision.description = manifest.description ? manifest.description : revision.description;
                revision.manifest = manifest;
            }
        } else {
            console.log('No manifest found');
        }
    }

    initShowRevisions() {
        this.showRevision = [];
        for (let index = 0; index < this.software.data.revisions.length; index++) {
            if (index === this.software.data.revisions.length - 1) {
                this.showRevision[index] = true;
            } else {
                this.showRevision[index] = false;
            }
        }
    }

    toggleShowRevision(index) {
        this.showRevision[index] = !this.showRevision[index];
    }

    notify(message, duration = 3000) {
        this.snackBar.open(message, 'OK', {
            duration,
            panelClass: ['war-snackbar']
        });
    }

    notifyError(message, duration = 5000) {
        this.snackBar.open(message, 'OK', {
            duration,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }
}
